import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { ReceiveConfigList, ReceiveConfigDetail } from '@/api/ceshi_xiugai/popularize';
export default {
  data: function data() {
    return _defineProperty(_defineProperty(_defineProperty(_defineProperty({
      //系统教程
      tab: 0,
      loading: true,
      course: this.$route.query.course,
      //系统教程
      radio: 1,
      title: '数据接收详情',
      dialogReceive: false,
      value: '',
      tableData: [],
      start_time: '',
      end_time: '',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      type: 0,
      receiveTime: '',
      form: {
        date: '',
        date1: ''
      },
      formLabelWidth: '120px',
      personLabel: [],
      personLabel1: [],
      activeName: 'first'
    }, "type", 1), "receiveId", ''), "showMsg", false), "showMsg1", false);
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.GetList(this.query.page, this.query.pagesize);
    } else {}
  },
  computed: {},
  components: {
    Head: Head
  },
  methods: {
    GetList: function GetList(page, pagesize) {
      var _this = this;
      this.loading = true;
      ReceiveConfigList({
        page: String(page),
        pagesize: String(pagesize),
        start_date: this.start_time,
        end_date: this.end_time
      }).then(function (respomse) {
        _this.loading = false;
        _this.tableData = respomse.data.data;
        _this.query.total = respomse.data.total;
      });
    },
    //时间戳查询
    DataTime: function DataTime() {
      var _this2 = this;
      if (this.receiveTime == '' || this.receiveTime == null) {
        this.start_time = '';
        this.end_time = '';
        this.$nextTick(function () {
          _this2.GetList(1, _this2.query.pagesize);
        });
      } else {
        var s_time = this.receiveTime[0].toString();
        this.start_time = s_time.substring(0, 10);
        var e_time = this.receiveTime[1].toString();
        this.end_time = e_time.substring(0, 10);
        this.$nextTick(function () {
          _this2.GetList(1, _this2.query.pagesize);
        });
      }
    },
    formatDate: function formatDate(row) {
      return row.receive_date.split(' ')[0];
    },
    //分页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this3 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this3.GetList(_this3.query.page, _this3.query.pagesize);
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this4 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this4.GetList(_this4.query.page, _this4.query.pagesize);
      });
    },
    detail: function detail(val) {
      var _this5 = this;
      this.dialogReceive = true;
      this.loading = true;
      this.receiveId = val;
      ReceiveConfigDetail({
        type: this.type,
        flow_receive_config_id: this.receiveId
      }).then(function (respomse) {
        _this5.loading = false;
        var data = respomse.data;
        if (data == '') {
          _this5.showMsg = true;
        } else {
          _this5.showMsg = false;
          _this5.form.date = data.receive_date;
          _this5.personLabel = data.users;
        }
      });
    },
    handleClick: function handleClick() {
      var _this6 = this;
      if (this.activeName == 'second') {
        this.type = 2;
        ReceiveConfigDetail({
          type: this.type,
          flow_receive_config_id: this.receiveId
        }).then(function (respomse) {
          _this6.loading = false;
          var data = respomse.data;
          if (data == '') {
            _this6.showMsg1 = true;
          } else {
            _this6.showMsg1 = false;
            _this6.form.date1 = data.receive_date;
            _this6.personLabel1 = data.users;
          }
        });
      } else {
        this.type = 1;
        ReceiveConfigDetail({
          type: this.type,
          flow_receive_config_id: this.receiveId
        }).then(function (respomse) {
          _this6.loading = false;
          var data = respomse.data;
          if (data == '') {
            _this6.showMsg = true;
          } else {
            _this6.showMsg = false;
            _this6.form.date = data.receive_date;
            _this6.personLabel = data.users;
          }
        });
      }
    },
    cancel: function cancel() {
      this.type = 1;
      this.activeName = 'first';
    }
  }
};